<template lang="pug">
  .scholarship-info
    VoucherCard(:voucher="voucher" v-if="hasVoucher")
    PriceCard(:course="data.course" :offer="data.offer")
    SubscriptionCard(:order="order", :offer="data.offer")
    OfferCard(v-if="!isOrderCompleted" :offer="data.offer" :followUp="followUp" :hasUser="hasUser" :order="order")
    CourseCard(:course="data.course" :offer="data.offer")
    ExtraInfoCard(:extraInfo="getExtraInfo" v-if="canShowExtraInfo")
    a.scholarship-info__button(
      @click="showDetails"
      title="Detalhes e regras da vaga (\\+i)"
      v-keycut:i.click=""
    ) Ver detalhes e regras da vaga
    ParcelamentoDataCard(:offer="data.offer" :course="data.course" v-if="data.offer.hasParcelamento")
</template>

<script>
import { mapActions } from 'vuex';
import CourseCard from '@/components/Profile/Scholarship/CourseCard';
import ExtraInfoCard from '@/components/Profile/Scholarship/ExtraInfoCard';
import OfferCard from '@/components/Profile/Scholarship/OfferCard';
import ParcelamentoDataCard from '@/components/Profile/Scholarship/ParcelamentoDataCard';
import PriceCard from '@/components/Profile/Scholarship/PriceCard';
import SubscriptionCard from '@/components/Profile/Scholarship/SubscriptionCard';
import VoucherCard from '@/components/Profile/ManageVoucher/VoucherCard';

export default {
  components: {
    CourseCard,
    ExtraInfoCard,
    OfferCard,
    ParcelamentoDataCard,
    PriceCard,
    SubscriptionCard,
    VoucherCard,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    followUp: {
      type: Object,
      default: null,
    },
    order: {
      type: Object,
      required: false,
      default: null,
    },
    hasUser: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    coupon() {
      return this.data.coupon;
    },
    isOrderCompleted() {
      return this.order && this.order.status === 'completed';
    },
    canShowExtraInfo() {
      if (!this.coupon) return Boolean(this.offerBenefit || this.offerWarning);
      return Boolean(this.couponBenefit || this.couponWarning);
    },
    getExtraInfo() {
      if (this.coupon) {
        return {
          warnings: this.couponWarning,
          benefits: this.couponBenefit,
        };
      } else {
        return {
          warnings: this.offerWarning,
          benefits: this.offerBenefit,
        };
      }
    },
    couponWarning() {
      return this.coupon.extra_warning;
    },
    couponBenefit() {
      return this.coupon.extra_benefit;
    },
    offerWarning() {
      return this.data.extraInfo.warnings;
    },
    offerBenefit() {
      return this.data.extraInfo.benefits;
    },
    voucher() {
      return this.order && this.order.voucher;
    },
    hasVoucher() {
      return Boolean(this.voucher);
    },
  },
  methods: {
    ...mapActions('RightPanel', ['tabChange']),
    showDetails() {
      this.tabChange({ key: 'offer-details', params: { data: this.data } });
    },
  },
};
</script>

<style lang="sass" scoped>
.scholarship-info__button
  display: block
  width: 100%
  cursor: pointer
  font-weight: $fw-bold
  color: $white
  padding: $space-xs $space-xs $space-xs 0
  margin-bottom: $space-st
</style>
