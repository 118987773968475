import { isMatch as _isMatch } from 'lodash';

export const state = () => ({
  panelOn: true,
  tabStack: [],
});

export const mutations = {
  setPanelOn(state, payload) {
    state.panelOn = payload;
  },
  stackAdd(state, tabObject) {
    state.tabStack.push(tabObject);
  },
  stackGoBack({ tabStack }) {
    tabStack.pop();
  },
  stackClear(state) {
    state.tabStack = [];
  },
  stackSplice(state, index) {
    state.tabStack.splice(index);
  },
};

export const getters = {
  tabActive(state) {
    const lastIndex = state.tabStack.length - 1;
    const lastTab = state.tabStack[lastIndex];

    return lastTab ? state.tabStack[lastIndex] : {};
  },
  panelActive(state) {
    return state.panelOn;
  },
  hasStackedTabs({ tabStack }) {
    return tabStack.length > 1;
  },
  hasTabs({ tabStack }) {
    return tabStack.length > 0;
  },
};

export const actions = {
  showPanel({ commit }) {
    commit('setPanelOn', true);
  },
  hidePanel({ commit }) {
    commit('setPanelOn', false);
  },
  tabChange(
    { state, commit, dispatch },
    { key, params = {}, clearStack = false, replace = false }
  ) {
    const VALID_TYPES = [
      'home',
      'order',
      'billings',
      'financial-data',
      'register-user',
      'register-contact',
      'transfer',
      'offer-details',
      'scholarship',
      'choose-user',
      'register-bo',
      'register-order',
      'send-bill',
      'expires-boleto',
      'admission-documents',
      'ticket-transfer',
      'payments-list',
      'refund-request',
      'refund-request-cancel',
      'exchangeScholarship',
      'send-purchase-confirmation-email',
      'update-ticket',
      'manual-ticket-authorization',
      'send-refund-email',
      'audit-request',
      'create-login',
      'password-recovery',
      'merge-user',
      'update-follow-up-step',
      'resend-digital-contract',
      'update-agreement-state',
      'manage-voucher',
      'reactivate-voucher',
      'update-enrollment-semester',
      'activate-digital-admission',
      'reactivate-user',
      'block-communication',
      'update-email-login',
      'lgpd-delete-register',
      'restricted-offer',
    ];

    if (clearStack) {
      commit('stackClear');
    }

    if (!VALID_TYPES.includes(key)) {
      console.error(`key: ${key} not mapped in VALID_TYPES: ${VALID_TYPES}`);
      return;
    }

    if (replace) commit('stackGoBack');

    const duplicateIndex = state.tabStack.findIndex(item =>
      _isMatch(item, { key, params })
    );
    const hasDuplicate = duplicateIndex !== -1;

    if (hasDuplicate) {
      dispatch('goBackToTab', duplicateIndex);
      return;
    }

    commit('stackAdd', { key, params });
    if (!state.panelOn) {
      commit('setPanelOn', true);
    }
  },
  reset({ commit }) {
    commit('stackClear');
  },
  tabGoBack({ commit, state }) {
    commit('stackGoBack');
    if (state.tabStack.length === 0) {
      commit('setPanelOn', false);
    }
  },
  goBackToTab({ commit }, index) {
    commit('stackSplice', index + 1);
  },
};
