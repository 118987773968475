<template lang="pug">
  .ticket-transfer
    TabHeader(title="Tranferência de atendimento")

    .ticket-transfer__body
      template(v-if="!loadedContacts")

      template(v-else-if="nothingToTransfer")
        | Nada para transferir.

      template(v-else="")
        .opa__card
          .chat-tranfer__description Selecione o chat que você deseja transferir
          Select.ticket-transfer__select(
            placeholder="Escolher chat"
            :list="formatWhatsappChats(chats)"
            v-model="selectedOptions.chatSelected"
            label-property="whatsappUsernameTranslated"
          )
        template(v-if="selectedOptions.chatSelected")
          Card.opa__card(v-if="selectableOptions.attendants")
            FocusHolder
            template(slot="header")
              .opa__card-header Para guia
            .opa__card-body
              .chat-tranfer__description Para qual guia você quer tranferir?
              Select.ticket-transfer__select(
                placeholder="Escolher guia"
                :list="selectableOptions.attendants"
                v-model="selectedOptions.attendant"
                label-property="name"
              )
              Button.ticket-transfer__button(
                :text="loading.attendant ? 'Aguarde...' : 'Transferir para guia'"
                type="positive"
                :disabled="!selectedOptions.attendant"
                :loading="loading.attendant"
                @click="transferClicked('attendant')"
              )
          Card.opa__card(v-if="selectableOptions.queues")
            template(slot="header")
              .opa__card-header Para a fila
            .opa__card-body
              .chat-tranfer__description Para qual fila você quer transferir?
              Select.ticket-transfer__select(
                placeholder="Escolher fila"
                :list="selectableOptions.queues"
                v-model="selectedOptions.queue"
                label-property="name"
              )
              Button.ticket-transfer__button(
                :text="loading.queue ? 'Aguarde...' : 'Transferir para fila'"
                type="positive"
                :disabled="!selectedOptions.queue"
                :loading="loading.queue"
                @click="transferClicked('queue')"
              )
          Card.opa__card(v-if="selectableOptions.whatsappHosts")
            template(slot="header")
              .opa__card-header Para o Número
            .opa__card-body
              .chat-tranfer__description Para qual número você deseja direcionar este atendimento?
              Select.ticket-transfer__select(
                placeholder="Escolha o número"
                :list="selectableOptions.whatsappHosts"
                v-model="selectedOptions.whatsappHost"
                label-property="name"
              )
              Button.ticket-transfer__button(
                :text="'Enviar Mensagem de Transferência'"
                type="positive"
                :disabled="!(selectedOptions.chatSelected && selectedOptions.whatsappHost)"
                ref="activeButton"
                @click="transferToHostClicked"
              )
</template>

<script>
import Card from '@/components/common/Card';
import TabHeader from '@/components/common/TabHeader';
import Select from '@/components/common/Select';
import Button from '@/components/common/Button';
import FocusHolder from '@/components/common/FocusHolder';
import * as WhatsappUsername from '@/assets/javascripts/data/whatsapp/whatsappUsername';
import { whatsappHosts } from '@/assets/javascripts/data/whatsapp/hosts';
import { mapActions, mapGetters } from 'vuex';
import TicketAPI from '@/api/ticketAPI';

export default {
  components: {
    Select,
    Card,
    TabHeader,
    Button,
    FocusHolder,
  },

  props: {
    params: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      selectedOptions: {
        whatsappHost: null,
        attendant: null,
        chatSelected: null,
        queue: null,
      },
      selectableOptions: {
        queues: null,
        attendants: null,
        whatsappHosts: null,
      },
      customerContacts: null,
      chats: null,
      loading: {
        attendant: false,
        queue: false,
      },
    };
  },

  computed: {
    ...mapGetters('Tickets', ['formatWhatsappChats']),
    loadedContacts() {
      return this.custumerContacts !== null;
    },

    allContacts() {
      return this.chats.length == 1 && this.customerContacts.length == 1;
    },

    hasNoChats() {
      return !this.chats || this.chats.length === 0;
    },

    nothingToTransfer() {
      return this.loadedContacts && this.hasNoChats;
    },
  },

  watch: {
    async 'selectedOptions.chatSelected'({ whatsappUsername }) {
      if (!this.selectedOptions.chatSelected) return;

      const whatsappHostKey = WhatsappUsername.getWhatsappHost(
        whatsappUsername
      );

      const options = await this.getTransferOptions({
        whatsappHostKey,
        tenantId: this.params.tenantId,
      });

      if (options) {
        this.selectableOptions.queues = options.queues;
        this.selectableOptions.attendants = options.attendants;
      }

      this.selectableOptions.whatsappHosts = this.filterHostsMapBy(
        whatsappHostKey
      );
    },
  },

  async mounted() {
    const { contacts, chats } = await this.getDraftCustomerContactsAndChats(
      this.params.customerId
    );
    this.customerContacts = contacts;
    this.chats = chats;
  },

  methods: {
    ...mapActions('Tickets', [
      'contactClosed',
      'getDraftCustomerContactsAndChats',
    ]),
    ...mapActions('Whatsapp', ['getTransferOptions']),
    ...mapActions('TicketTransfer', ['transferToHost']),
    ...mapActions('StoreManager', ['notify']),

    async transferClicked(targetType) {
      const { whatsappUsername } = this.selectedOptions.chatSelected;
      const { customerId } = this.params;

      await this.ticketTransferSubmit(targetType);

      this.contactClosed({
        allContacts: this.allContacts,
        contactTypes: ['whatsapp'],
        customerId,
        whatsappUsername,
        emitter: 'transfer',
      });
    },

    async ticketTransferSubmit(targetType) {
      this.loading[targetType] = true;

      const {
        customer_contact_id: customerContactId,
      } = this.getSelectedContact();
      const { id: whatsappChatId } = this.selectedOptions.chatSelected;
      const targetSelected = this.selectedOptions[targetType];

      try {
        await TicketAPI.transferTicket(
          customerContactId,
          targetType,
          targetSelected,
          whatsappChatId
        );
      } catch (error) {
        this.notify({
          errors: {
            errorTitle: 'ticketTransferSubmit | transferTicket',
            errorDetails: error,
          },
        });
      }

      this.loading[targetType] = false;
    },

    async transferToHostClicked() {
      const {
        customer_contact_id: customerContactId,
      } = this.getSelectedContact();

      const { chatSelected, whatsappHost } = this.selectedOptions;

      await this.transferToHost({
        customerContactId,
        whatsappUsername: chatSelected.whatsappUsername,
        whatsappHost: whatsappHost.key,
        allContacts: this.allContacts,
        customerId: this.params.customerId,
      });
    },

    getSelectedContact() {
      return this.customerContacts.find(cc => cc.contact_type == 'whatsapp');
    },

    filterHostsMapBy(currentHost) {
      return whatsappHosts.filter(host => host.key !== currentHost);
    },
  },
};
</script>

<style lang="sass">
.ticket-transfer
  background-color: $space-gray
  height: 100vh
  display: flex
  flex-direction: column

.ticket-transfer__body
  padding: $space-l $space-l 0 $space-l
  overflow: auto
  flex-grow: 1

.chat-tranfer__description
  margin-bottom: $space-s
  font-weight: bold

.ticket-transfer__select
  margin-bottom: $space-m

.ticket-transfer__button
  width: 100%

.ticket-transfer__contact-types-title
  font-weight: $fw-semibold
  margin-bottom: $space-s
</style>
