<template lang="pug">
  .register-contact
    TabHeader(title="Registro de atendimento")

    form.register-contact__body(@submit.prevent="submit")
      template(v-if="!loadedContacts")

      template(v-else-if="nothingToRegister")
        .register-contact__item-empty
          | Não há atendimentos para serem registrados.

      template(v-else="")
        .register-contact__item.register-contact__item--choose-contacts
          .register-contact__label
            | Atendimentos que serão registrados
          template(v-for="cc in customerContacts")
            .register-contact__l-checkbox
              Checkbox(
                v-model="cc.selected"
                :label="cc.contact_type | contactTypeTranslate"
              )
                template(#default="slotProps")
                  Icon.register-contact__label-icon(:svgName="cc.contact_type | contactTypeIcon")
                  | {{ slotProps.label }}

      template(v-if="loadedContacts")
        .new-customer-contact(v-if="!newCustomerContactClicked")
          button.new-customer-contact__contact-type-btn(@click="newCustomerContact")
            icon(svg-name="i-plus", svg-class="new-customer-contact__icon")
            | Adicionar novo atendimento
        template(v-else)
          Select.new-customer-contact__contact-type-select(
            placeholder="Escolha um atendimento"
            :list="possibleContactTypes"
            v-model="selectedPossibleContactType"
            :autofocus="true"
            labelProperty="label")

      template(v-if="!nothingToRegister && loadedContacts")
        .register-contact__item
          .register-contact__label
            | Tipo do atendimento
          Select.register-contact__select(
            placeholder="Informe o tipo do registro"
            :list="reasons"
            v-model="selectedReason"
            :autofocus="true")
            template(slot-scope="{ item }")
              .register-contact__reason
                .register-contact__group {{ item.group }}
                .register-contact__name  {{ item.reason }}

        template(v-if="this.hasWhatsappContactSelected && chats")
          .register-contact__label Selecione o chat que você deseja registrar
          Select.register-contact__select(
            placeholder="Escolher chat"
            :list="formatWhatsappChats(chats)"
            v-model="chatSelected"
            label-property="whatsappUsernameTranslated"
          )

        .register-contact__item
          .register-contact__label
            | Se necessário informe alguma observação
          textarea.register-contact__textarea(
            v-model="observations"
            placeholder="Ex.: O aluno não conseguiu efetuar o pagamento no Checkout, porque o site estava fora do ar"
          )

        .register-contact__item
          .register-contact__label
            | Anexo de arquivos
          button.register-contact__attachment-button(
            type="button"
            @click="attachmentButtonClicked"
          )
            icon.register-contact__attachment-icon(
              svg-name="i-attach"
              svg-alt="Anexar arquivo"
            )
            .register-contact__attachment-text
              .opa-bold Anexar
              | {{ attachmentsMessage }}

          FileSelector(ref="fileSelector" @filesSelected="onFilesSelected")

        Alert.register-contact__alert(
          :message="alertMessage"
          type="error"
          :dismissable="false"
        )

        Button.register-contact__button(
          type="positive"
          :disabled="hasErrors || submited"
          :text="submitting ? 'Aguarde...' : 'Registrar contato'"
          :submit="true"
          :loading="submitting")
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Rollbar from '@/common_modules/tools/Rollbar';
import {
  contactTypeTranslate,
  contactTypeIcon,
} from '@/assets/javascripts/data/customerContact';

import Alert from '@/components/common/Alert';
import Button from '@/components/common/Button';
import Card from '@/components/common/Card';
import Checkbox from '@/components/common/Checkbox';
import FileSelector from '@/components/common/FileSelector';
import formatFeedbackMessage from '@/assets/javascripts/formatters/FeedbackMessageFormatter.js';
import Icon from '@/components/common/Icon';
import { possibleContactTypes } from '@/assets/javascripts/data/customerContact';
import Select from '@/components/common/Select';
import TabHeader from '@/components/common/TabHeader';
import ticketAPI from '@/api/ticketAPI';

export default {
  components: {
    Alert,
    Button,
    Card,
    Checkbox,
    FileSelector,
    Icon,
    Select,
    TabHeader,
  },

  filters: {
    contactTypeIcon,
    contactTypeTranslate,
  },

  props: {
    params: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    reasons: [],
    selectedReason: undefined,
    observations: '',
    alertMessage: null,
    submitting: false,
    submited: false,
    customerContacts: [],
    chats: [],
    chatSelected: null,
    loadedContacts: false,
    newCustomerContactClicked: false,
    selectedPossibleContactType: null,
    attachments: [],
    possibleContactTypes,
  }),

  computed: {
    ...mapGetters('Tickets', ['formatWhatsappChats']),
    hasErrors() {
      const reasonNotSelected = !(
        this.selectedReason && this.selectedReason.id
      );

      const contactNotSelected =
        this.customerContacts.length > 0 &&
        this.selectedCustomerContactIds.length === 0;

      if (this.hasWhatsappContactSelected) {
        return reasonNotSelected || contactNotSelected || !this.chatSelected;
      }

      return reasonNotSelected || contactNotSelected;
    },

    selectedCustomerContactIds() {
      return this.customerContacts
        .filter(contact => contact.selected)
        .map(contact => contact.customer_contact_id);
    },

    selectedCustomerContactTypes() {
      return this.customerContacts
        .filter(contact => contact.selected)
        .map(contact => contact.contact_type);
    },

    nothingToRegister() {
      return !this.customerContacts || this.customerContacts.length === 0;
    },

    attachmentsMessage() {
      const { length } = this.attachments;

      return `${length} ${this.$pluralize('arquivo', length)} ${this.$pluralize(
        'anexado',
        length
      )}`;
    },

    hasWhatsappContactSelected() {
      return this.selectedCustomerContactTypes.includes('whatsapp');
    },

    allContacts() {
      if (this.hasWhatsappContactSelected) {
        return this.allCostumerContacts && this.chats.length == 1;
      }

      return this.allCostumerContacts;
    },

    allCostumerContacts() {
      return (
        this.customerContacts.length == this.selectedCustomerContactIds.length
      );
    },

    whatsappUsername() {
      return this.chatSelected ? this.chatSelected.whatsappUsername : '';
    },
  },

  watch: {
    async selectedPossibleContactType(contactType) {
      if (contactType == null) return;
      await this.startNewCustomerContact(contactType);
      await this.populateCustomerContactsAndChats();
      this.selectedPossibleContactType = null;
      this.newCustomerContactClicked = false;
    },
  },

  async created() {
    await this.populateCustomerContactsAndChats();

    const {
      data: { results },
    } = await ticketAPI.getContactReasons(this.params.tenantId);
    this.reasons = results;
  },

  methods: {
    ...mapActions('Tickets', [
      'startCustomerContact',
      'getDraftCustomerContactsAndChats',
      'registerContactsSubmited',
    ]),

    async populateCustomerContactsAndChats() {
      const { contacts, chats } = await this.getDraftCustomerContactsAndChats(
        this.params.customerId
      );

      this.customerContacts = contacts.map(item => ({
        ...item,
        selected: true,
      }));

      this.chats = chats;
      this.loadedContacts = true;
    },

    startNewCustomerContact(contactType) {
      return this.startCustomerContact({
        customerId: this.params.customerId,
        contactType: contactType.value,
      });
    },

    newCustomerContact() {
      this.newCustomerContactClicked = true;
    },

    async submit() {
      this.submitting = true;
      this.alert = undefined;

      try {
        await this.registerContactsSubmited({
          allContacts: this.allContacts,
          contactReasonId: this.selectedReason.id,
          customerContactIds: this.selectedCustomerContactIds,
          contactTypes: this.selectedCustomerContactTypes,
          observations: this.observations,
          customerId: this.params.customerId,
          whatsappUsername: this.whatsappUsername,
          attachments: this.attachments,
        });
      } catch (error) {
        const message = formatFeedbackMessage(error);

        this.alertMessage = message || 'Ocorreu um erro ao registrar o contato';

        Rollbar.error('RegisterContact | error to submit', {
          error,
          customerContactIds: this.selectedCustomerContactIds,
        });
        this.submitting = false;
      }
    },

    attachmentButtonClicked() {
      this.$refs.fileSelector.openFileSelector();
    },

    onFilesSelected(files) {
      files.forEach(({ fileName, data }) =>
        this.attachments.push({ fileName, data })
      );
    },
  },
};
</script>
