import _ from 'lodash';
import HttpService from './HttpService';

const service = {
  async getData(id) {
    const { data } = await HttpService.get(`/api/base_user/${id}`);

    return data;
  },
  async getAddress(cep) {
    const address = await HttpService.get(`api/cep/${cep}`).then(
      ({ data }) => ({
        neighborhood: data.bairro,
        state: data.estado,
        city: data.cidade,
        address: data.endereco,
      })
    );

    return address;
  },
  searchUserFull({ searchBy, value }, tenantId) {
    const keyTypes = ['cpf', 'email', 'phone', 'name', 'email_login'];
    if (!keyTypes.includes(searchBy)) {
      throw 'ProfileService.searchUserFull keyType value error!';
    }

    const params = {
      [searchBy]: value,
      tenant_id: tenantId,
    };

    return HttpService.get('search/users_full', params);
  },
  searchUser({ searchBy, value, minimal = true }, tenantId) {
    const keyTypes = [
      'cpf',
      'email',
      'phone',
      'name',
      'base_user_id',
      'email_login',
    ];
    if (!keyTypes.includes(searchBy)) {
      throw 'ProfileService.searchUser keyType value error!';
    }

    const params = {
      [searchBy]: value,
      tenant_id: tenantId,
      minimal,
    };

    return HttpService.get('search/users', params);
  },
  async saveProfile(userInfos) {
    const { data } = await HttpService.patch(
      `api/base_user/${userInfos.id}`,
      userInfos
    );

    return data;
  },
  async createUser({ name, email, tenantIdentifier }) {
    const { data } = await HttpService.post('api/base_user', {
      email,
      name,
      tenant_identifier: tenantIdentifier,
    });

    return data;
  },
  async validateEmail(email) {
    const { data } = await HttpService.get(
      `api/base_user/validate_email/${email.email}`
    );

    return data;
  },
  async createLogin({ baseUserId, name, email, tenantIdentifier }) {
    const { data } = await HttpService.post('api/base_user/create_login', {
      email,
      base_user_id: baseUserId,
      name,
      tenant_identifier: tenantIdentifier,
    });

    return data;
  },
  async updateEmailLogin({ baseUserId, email }) {
    const { data } = await HttpService.post(
      'api/base_user/update_email_login',
      {
        email_login: email,
        base_user_id: baseUserId,
      }
    );

    return data;
  },
  async getRelatedProfiles(profile, tenantId) {
    const mapKeysToSearchBy = {
      cpf: 'cpf',
      phones: 'phone',
      emails: 'email',
    };

    const makeRequest = (value, key) => {
      if (!value) return;

      if (_.isArray(value)) {
        return value.map(value => makeRequest(value, key));
      }

      const searchParams = {
        searchBy: mapKeysToSearchBy[key],
        value,
      };

      return service
        .searchUser(searchParams, tenantId)
        .then(({ data: { users } }) => users);
    };

    const requests = _(profile) //
      .pick(['cpf', 'phones', 'emails'])
      .map(makeRequest)
      .flatten()
      .value();

    const results = _(await Promise.all(requests)) //
      .flatten()
      .filter()
      .uniqBy('base_user_id')
      .value();

    return results;
  },
  async getFollowUp(baseUserId) {
    const { data } = await HttpService.post(
      `api/base_user/${baseUserId}/follow_up`
    );

    return data;
  },
  async passwordRecovery(email) {
    const { data } = await HttpService.get(
      `api/base_user/send_recovery_password_email/${email}`
    );

    return data;
  },
  async sendgridEmailStatus(baseUserId) {
    const { data } = await HttpService.get(
      `api/base_user/${baseUserId}/sendgrid_email_status`
    );

    return data;
  },
  async lgpdDeleteRegister({ baseUserId, observations }) {
    const data = await HttpService.post(
      `api/base_user/${baseUserId}/lgpd_delete_register`,
      { observations }
    );

    return data;
  },
  async createOrderFromRestrictedOffer({
    baseUserId,
    restrictedOfferId,
    selectedOption,
    specificOrderValue = null,
  }) {
    const payload = {
      restricted_offer_id: restrictedOfferId,
      selected_option: selectedOption,
    };

    if (specificOrderValue !== null) {
      payload.specific_order_value = specificOrderValue;
    }

    const data = await HttpService.post(
      `api/base_user/${baseUserId}/create_order_from_restricted_offer`,
      payload
    );

    return data;
  },
};

export default service;
