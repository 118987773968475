import { convertToCamelCase } from '@/assets/javascripts/modules/utils';
import ProfileService from '@/assets/javascripts/services/ProfileService.js';

import { pick } from 'lodash';
import { waitPromise } from '@/assets/javascripts/modules/utils';

const getInitialState = () => ({
  profile: {
    name: 'Anônimo',
    followUp: {},
    phones: [],
  },
  profileLoading: false,
  user: {},
  relatedProfiles: [],
  chooseProfile: { choosing: false, originalId: null },
  UI: {
    canChangeBaseUser: false,
  },
});

export const state = getInitialState;

export const mutations = {
  setProfile(state, profile) {
    state.profile = profile;
  },
  setProfileLoading(state, value) {
    state.profileLoading = value;
  },
  setUser(state, user) {
    state.user = user;
  },
  initializeState(state) {
    Object.assign(state, getInitialState());
  },
  setFollowUp(state, newFollowUp) {
    state.profile = { ...state.profile, followUp: newFollowUp };
  },
  setRelatedProfiles(state, profiles) {
    state.relatedProfiles = profiles;
  },
  startChooseProfile(state) {
    state.chooseProfile = {
      choosing: true,
      originalId: state.profile.id,
    };
  },
  stopChooseProfile(state) {
    state.chooseProfile = {
      choosing: false,
      originalId: null,
    };
  },
  updatetUI(state, obj) {
    state.UI = { ...state.UI, ...obj };
  },
};

export const getters = {
  isThisUser: state => id => state.user.base_user === id,
  userEmails: state => state.profile.emails,
  userPhones: state => state.profile.phones,
  customerId: state => state.profile.customerId,
  canTransfer: state =>
    !(state.UI.canChangeBaseUser && state.chooseProfile.choosing),
  canRegister: state =>
    !(state.UI.canChangeBaseUser && state.chooseProfile.choosing),
  canChangeBaseUser: state => state.UI.canChangeBaseUser,
  isChoosingProfile: state => state.chooseProfile.choosing,
  user: state => state.user,
  hasLogin: state => (state.profile.logins || []).length > 0,
  currentTenant: (x, y, z, rootGetters) =>
    rootGetters['Admin/getCurrentTenant'],
};

export const actions = {
  showProfileHome(
    { dispatch, commit, state },
    { baseUserId = state.user.base_user, canChangeBaseUser = false } = {}
  ) {
    dispatch(
      'RightPanel/tabChange',
      {
        key: 'home',
        clearStack: true,
      },
      { root: true }
    );

    commit('initializeState');
    commit('updatetUI', { canChangeBaseUser });
    dispatch('updateUser', baseUserId);
  },
  chooseProfile({ dispatch, commit, state, getters }, { base_user_id }) {
    if (getters.isThisUser(base_user_id)) return;

    if (!state.chooseProfile.choosing) {
      commit('startChooseProfile');
    } else if (state.chooseProfile.originalId === base_user_id) {
      commit('stopChooseProfile');
    }

    dispatch('updateUser', base_user_id);
  },
  async refreshProfile({ state, commit }) {
    const profile = await ProfileService.getData(state.user.base_user);
    commit('setProfile', profile);
  },
  async updateUser({ commit, dispatch, getters }, baseUserId) {
    commit('setProfileLoading', true);
    commit('setUser', { base_user: baseUserId });

    const profile = await ProfileService.getData(baseUserId);

    if (!getters.isThisUser(baseUserId)) return;

    dispatch('updateProfile', profile);
    dispatch('getRelatedProfiles', profile);
    dispatch('getFollowUp', profile.id);
  },
  async getFollowUp({ commit }, baseUserId) {
    const followUp = await ProfileService.getFollowUp(baseUserId);
    if (!getters.isThisUser(baseUserId)) return;

    commit('setFollowUp', followUp);
  },
  async getRelatedProfiles({ commit, getters, state }, profile) {
    if (state.chooseProfile.choosing) return;
    const currentTenant = getters.currentTenant;

    const relatedProfiles = await ProfileService.getRelatedProfiles(
      profile,
      currentTenant.id
    );

    if (!getters.isThisUser(profile.id)) return;

    commit('setRelatedProfiles', relatedProfiles);
  },
  updateProfile({ commit }, newProfile) {
    commit('setProfile', newProfile);
    commit('setProfileLoading', false);
  },
  setFollowUp({ commit }, newFollowUp) {
    commit('setFollowUp', newFollowUp);
  },
  getAddress(store, cep) {
    return ProfileService.getAddress(cep);
  },
  async searchUser({ getters }, { searchBy, value }) {
    const currentTenant = getters.currentTenant;

    const {
      data: { users },
    } = await ProfileService.searchUser({ searchBy, value }, currentTenant.id);
    return convertToCamelCase(users);
  },
  async searchUserFull({ getters }, { searchBy, value }) {
    const currentTenant = getters.currentTenant;

    const {
      data: { users },
    } = await ProfileService.searchUserFull(
      { searchBy, value },
      currentTenant.id
    );
    return convertToCamelCase(users);
  },
  async saveProfile({ state, dispatch }, data) {
    const response = await ProfileService.saveProfile({
      id: state.profile.id,
      ...data,
    });

    // Somehow in dev when reloading the profile just after updating it
    // the cpf comes with the old value (null)
    // with this timeuout it works correctly
    await waitPromise(1000);

    const newProfile = await ProfileService.getData(state.user.base_user);
    dispatch('updateProfile', newProfile);
    if (!state.chooseProfile.choosing) {
      dispatch('updatedInfo', { profile: newProfile });
    }

    return response;
  },
  resetState({ commit }) {
    commit('initializeState');
  },
  validateEmail(store, email) {
    return ProfileService.validateEmail(email);
  },
  async profileSelect({ state, dispatch, commit }) {
    const { profile } = state;

    await dispatch('updatedInfo', { profile, full: true });

    commit('stopChooseProfile');
  },
  async chooseUser({ dispatch, commit }, baseUserId) {
    const newProfile = await ProfileService.getData(baseUserId);

    dispatch('updatedInfo', { profile: newProfile, full: true });

    commit('setProfile', newProfile);
    commit('setUser', { base_user: baseUserId });

    dispatch('RightPanel/tabGoBack', null, { root: true });
  },
  searchCourseClicked({ dispatch, state, getters }) {
    const params = {
      id: state.user.base_user,
      hasLogin: getters.hasLogin,
    };
    const location = pick(state.profile, ['city', 'state']);
    if (location.city && location.state) {
      params.location = location;
    }

    dispatch('StoreManager/Profile/showSearch', params, {
      root: true,
    });
  },
  ordersClicked({ dispatch, state }) {
    const baseUserId = state.user.base_user;

    dispatch('Order/ordersClicked', baseUserId, {
      root: true,
    });
  },
  async updatedInfo({ dispatch }, { profile, full }) {
    const params = {};
    params.name = profile.name;

    if (full) {
      params.newBaseUserId = profile.id;
      params.customerId = profile.customerId;
    }

    await dispatch('StoreManager/Profile/updatedInfo', params, { root: true });
  },
  transferClicked({ dispatch, state }) {
    dispatch(
      'StoreManager/Profile/transferClicked',
      {
        customerId: state.profile.customerId,
        tenantId: state.profile.tenantId,
      },
      { root: true }
    );
  },
  registerClicked({ dispatch, state }) {
    dispatch(
      'StoreManager/Profile/registerClicked',
      {
        customerId: state.profile.customerId,
        tenantId: state.profile.tenantId,
      },
      { root: true }
    );
  },
  registerUserOpened({ commit }, { registerType }) {
    if (registerType !== 'create') return;

    commit('updatetUI', { canChangeBaseUser: false });
  },
};
