<template lang="pug">
.right-panel(v-if="panelActive && hasTabs")
  component(v-if="tabComponent" :is="tabComponent" :params="tabActive.params")
</template>

<script>
import { mapGetters } from 'vuex';

import Billings from '@/components/Profile/Queropago/Billings';
import ChooseUser from '@/components/Profile/ChooseUser';
import DocumentsTab from '@/opa-modules/admissions/components/DocumentsTab';
import MergeTab from '@/components/Profile/MergeTab';
import ExpiresBoleto from '@/components/Scholarship/ExpiresBoleto';
import FinancialData from '@/components/Profile/Queropago/FinancialData';
import OfferDetails from '@/components/Profile/Scholarship/OfferDetails';
import Orders from '@/components/Profile/Scholarship/Orders';
import ProfileHome from '@/components/Profile/ProfileHome';
import RegisterBO from '@/components/Scholarship/RegisterBO';
import RegisterContact from '@/components/Profile/RegisterContact';
import RegisterOrder from '@/components/Scholarship/RegisterOrder';
import RegisterUser from '@/components/Profile/RegisterUser';
import ScholarshipTab from '@/components/Scholarship/ScholarshipTab';
import SendBill from '@/components/Scholarship/SendBill';
import TicketTransfer from '@/opa-modules/Tickets/components/TicketTransfer';
import PaymentsList from '@/components/Scholarship/PaymentsList';
import RefundRequest from '@/components/Scholarship/RefundRequest';
import RefundRequestCancel from '@/components/Scholarship/RefundRequestCancel';
import PurchaseConfirmation from '@/components/Scholarship/PurchaseConfirmation';
import UpdateTicket from '@/components/Scholarship/UpdateTicket';
import ManualTicketAuthorization from '@/components/Scholarship/ManualTicketAuthorization';
import SendRefundEmail from '@/components/Scholarship/SendRefundEmail';
import AuditRequest from '@/components/Scholarship/AuditRequest';
import CreateLogin from '@/components/Profile/CreateLogin';
import PasswordRecovery from '@/components/Profile/PasswordRecovery';
import FollowUpUpdateTab from '@/components/FollowUp/FollowUpUpdateTab';
import ResendDigitalContractTab from '@/components/FollowUp/ResendDigitalContractTab';
import UpdateAgreementStateTab from '@/components/FollowUp/UpdateAgreementStateTab';
import ManageVoucher from '@/components/Profile/ManageVoucher/ManageVoucher';
import ReactivateVoucher from '@/components/Profile/ManageVoucher/ReactivateVoucher';
import EnrollmentSemesterUpdateTab from '@/components/Coupon/EnrollmentSemesterUpdateTab';
import ActivateDigitalAdmissionTab from '@/components/FollowUp/ActivateDigitalAdmissionTab';
import ReactivateUser from '@/components/Profile/ReactivateUser';
import BlockCommunication from '@/components/Profile/BlockCommunication';
import UpdateEmailLogin from '@/components/Profile/UpdateEmailLogin';
import LgpdDeleteRegister from '@/components/Profile/LgpdDeleteRegister.vue';
import RestrictedOffer from '@/components/Profile/RestrictedOffer';

const componentsMap = {
  home: 'ProfileHome',
  order: 'Orders',
  'financial-data': 'FinancialData',
  billings: 'Billings',
  'register-user': 'RegisterUser',
  'register-contact': 'RegisterContact',
  'offer-details': 'OfferDetails',
  scholarship: 'ScholarshipTab',
  'choose-user': 'ChooseUser',
  'register-bo': 'RegisterBO',
  'register-order': 'RegisterOrder',
  'send-bill': 'SendBill',
  'expires-boleto': 'ExpiresBoleto',
  'admission-documents': 'DocumentsTab',
  'ticket-transfer': 'TicketTransfer',
  'refund-request': 'RefundRequest',
  'refund-request-cancel': 'RefundRequestCancel',
  'send-purchase-confirmation-email': 'PurchaseConfirmation',
  'payments-list': 'PaymentsList',
  'update-ticket': 'UpdateTicket',
  'manual-ticket-authorization': 'ManualTicketAuthorization',
  'send-refund-email': 'SendRefundEmail',
  'audit-request': 'AuditRequest',
  'create-login': 'CreateLogin',
  'password-recovery': 'PasswordRecovery',
  'merge-user': 'MergeTab',
  'update-follow-up-step': 'FollowUpUpdateTab',
  'resend-digital-contract': 'ResendDigitalContractTab',
  'update-agreement-state': 'UpdateAgreementStateTab',
  'manage-voucher': 'ManageVoucher',
  'reactivate-voucher': 'ReactivateVoucher',
  'update-enrollment-semester': 'EnrollmentSemesterUpdateTab',
  'activate-digital-admission': 'ActivateDigitalAdmissionTab',
  'reactivate-user': 'ReactivateUser',
  'block-communication': 'BlockCommunication',
  'update-email-login': 'UpdateEmailLogin',
  'lgpd-delete-register': 'LgpdDeleteRegister',
  'restricted-offer': 'RestrictedOffer',
};

export default {
  components: {
    Billings,
    ChooseUser,
    DocumentsTab,
    MergeTab,
    ExpiresBoleto,
    FinancialData,
    OfferDetails,
    Orders,
    PaymentsList,
    ProfileHome,
    RefundRequest,
    RefundRequestCancel,
    RegisterBO,
    RegisterContact,
    RegisterOrder,
    RegisterUser,
    ScholarshipTab,
    SendBill,
    TicketTransfer,
    PurchaseConfirmation,
    UpdateTicket,
    ManualTicketAuthorization,
    SendRefundEmail,
    AuditRequest,
    CreateLogin,
    PasswordRecovery,
    FollowUpUpdateTab,
    ResendDigitalContractTab,
    UpdateAgreementStateTab,
    ManageVoucher,
    ReactivateVoucher,
    ReactivateUser,
    EnrollmentSemesterUpdateTab,
    ActivateDigitalAdmissionTab,
    BlockCommunication,
    UpdateEmailLogin,
    LgpdDeleteRegister,
    RestrictedOffer,
  },
  computed: {
    ...mapGetters('RightPanel', ['tabActive', 'panelActive', 'hasTabs']),
    tabComponent() {
      return componentsMap[this.tabActive.key];
    },
  },
};
</script>
