import { map, mapValues, pick, get } from 'lodash';
import { convertToSnakeCase } from '@/assets/javascripts/modules/utils';
import HttpService from '@/assets/javascripts/services/HttpService.js';

export default {
  getDetails(uuid, baseUserId, tenantSlug) {
    const params = convertToSnakeCase({
      uuid,
      baseUserId,
      tenantSlug,
    });

    return HttpService.get('api/offers/offer_details', params);
  },
  async getRegisterBOOptions(baseUserId, { isOrder }) {
    // Foi indetificado que no opa antigo em alguns casos é adiconado
    // a lista de contatos o último contato independentemente dele estar associado ao guia.
    // Não consegui identificar a real necessidade disso,
    // mas fica a dica caso de algum problema no futuro.
    const param = convertToSnakeCase({ baseUserId: baseUserId });
    const params = baseUserId ? param : null;
    const { data } = await HttpService.get('api/bo/request_bo_data', params);
    let { boData: options } = data;

    let types;
    if (isOrder) {
      types = null;
    } else {
      types = [
        { key: 'Offer', name: 'Curso' },
        { key: 'Campus', name: 'Campus' },
        { key: 'University', name: 'Universidade' },
      ];
    }

    options = pick(options, ['reasons', 'contacts']);

    options = mapValues(options, value =>
      map(value, (key, value) => ({
        name: key,
        key: value,
      }))
    );

    options = { ...options, types };

    return options;
  },
  async registerBO(filters) {
    const { orderId, offerId, type } = filters;
    const relativeFilters = {};
    if (orderId) {
      relativeFilters.order_id = orderId;
    } else {
      relativeFilters.offer_id = offerId;
      relativeFilters.type = get(type, 'key');
    }

    const params = convertToSnakeCase({
      ...relativeFilters,
      baseUserId: filters.baseUserId,
      reason: filters.reason.key,
      observations: filters.observations,
      reclameAqui: filters.reclameAqui,
      contact: get(filters, 'contact.key'),
    });

    await HttpService.post('api/bo/register_bo', params);
  },

  async getOffer(offerId) {
    return HttpService.get(`api/offers/${offerId}`);
  },
};
