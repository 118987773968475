<template lang="pug">
  Card.order-card
    template(slot="header")
      .order-card__card-header
        div
          .order-card__card-title Matrícula
          | ID {{ order.id }}
        div
          .order-card__card-origin
            img(:src="originDetails.logo").origin__logo-img
    .order-card__body
      .order-card__line Origem:&nbsp;
        b {{ originDetails.name }}
      .order-card__line.order-card__important(v-if="isFreePreEnrollment")
        span Ingressou com Matrícula Direta
      .order-card__line Situação:
        Badge.order-card__status-badge(:type="orderStatus.color") {{ orderStatus.title }}
      .order-card__line(v-if="order.status === 'commited'") Vencimento: {{ paymentDueDate }}
      .order-card__line Tipo: {{ orderType }}
      template
        .order-card__line() Pagamento:&nbsp;
          b {{ paymentInfo.type }}
      template(v-if="paymentInfo.complement")
        .order-card__line() {{ paymentInfo.complement }}
      .order-card__line() Teste de Assinatura:&nbsp;
        b {{ order.subscriptionTest ? 'Sim' : 'Não' }}
      template(v-if="order.subscriptionTest && order.subscriptionType")
        .order-card__line() Recorrência de Assinatura:&nbsp;
          b {{ order.subscriptionType }}
      .order-card__line(v-if="time")
        Icon.order-card__icon(svg-name="i-schedule" svg-class="order-info__icon")
        span {{ time }}
</template>

<script>
import Card from '@/components/common/Card';
import Icon from '@/components/common/Icon';
import Badge from '@/components/common/Badge';
import getOrderStatusFormatting from '@/assets/javascripts/data/orderStatus.js';
import FollowUpModel from '@/models/FollowUp';
import { mapGetters } from 'vuex';
import { orderOriginDetails } from '@/assets/javascripts/data/orderOrigins/orderOrigins';
import moment from 'moment';

export default {
  components: {
    Card,
    Badge,
    Icon,
  },
  filters: {
    orderOriginDetails,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    followUp: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      originDetails: {},
    };
  },
  computed: {
    ...mapGetters('Order', ['orderOrigin']),
    isFreePreEnrollment() {
      return FollowUpModel.isFreePreEnrollment(this.followUp);
    },
    paymentInfo() {
      const { type, installments } = this.order.payment;
      const map = {
        CreditCard: 'Cartão de Crédito',
        Boleto: 'Boleto Bancário',
        Pix: 'PIX',
      };
      const paymentType = map[type] || 'N/A';

      let complement;
      if (typeof installments === 'number') {
        complement = 'Parcelas: ' + installments;
      }

      return { type: paymentType, complement };
    },
    paymentDueDate() {
      const { type, dueDate } = this.order.payment;

      const format = {
        Boleto: 'L',
        Pix: 'L - LT',
      }[type];

      return moment(dueDate).format(format);
    },
    orderStatus() {
      return getOrderStatusFormatting(this.order.status);
    },
    orderType() {
      if (this.order.dischargeType) return 'Quitação';
      if (this.isOrderCompleted) return 'Ordem Isenta';

      return 'PEF';
    },
    isOrderCompleted() {
      return this.order.status === 'completed';
    },
    time() {
      const { order } = this;

      const paymentType =
        {
          Boleto: 'Boleto',
          Pix: 'PIX',
        }[order.payment.type] || 'Código de Pagamento';

      switch (order.status) {
        case 'initiated':
          return `Iniciado em: ${this.getDate('initiated_at')}`;
        case 'registered':
          return `Registrado em: ${this.getDate('registered_at')}`;
        case 'commited':
          return `${paymentType} gerado em: ${this.getDate('commited_at')}`;
        case 'paid':
        case 'refund_requested':
        case 'refund_rejected':
        case 'refund_pending':
        case 'refunded':
        case 'refunded_by_voucher':
          return `Pago em: ${this.getDate('paid_at')}`;
        default:
          return undefined;
      }
    },
  },
  mounted() {
    this.originDetails = orderOriginDetails(this.orderOrigin);
  },
  methods: {
    getDate(key) {
      const date = this.order[key];
      return moment(date).format('L - LT');
    },
  },
};
</script>
