<template lang="pug">
  .restricted-offer(ref="form")
    TabHeader.restricted-offer__header(:title="'Vincular Oferta Restrita'")
    .restricted-offer__body
      .restricted-offer__title Vincular Oferta Restrita
      .restricted-offer__group
        label.restricted-offer__label Insira o ID da oferta restrita criada
        .restricted-offer__search
          input.restricted-offer__input(
            type="text"
              name="restrictedOfferId"
              v-model="formData.restrictedOfferId"
              :data-index="0"
              placeholder="Exemplo: 12345"
              autocomplete="off"
            )
          Button.restricted-offer-search__button.opa-btn--action(
            :disabled="!formData.restrictedOfferId"
            @click="searchOffer"
            text="Buscar oferta"
          )

        div(v-if="offerDetails")
          Card.review-request-card
            template(slot="header")
              .review-request-card__header
                Icon.review-request-card__icon(svg-name="i-error")
                .review-request-card__title Revise os dados da solicitação:

            p.review-request-card__items-list
              p.review-request-card__item Nome do curso: {{ offerData.courseName }}
              p.review-request-card__item Instituição: {{ offerData.university }}
              p.review-request-card__item Turno: {{ offerData.shift }}
              p.review-request-card__item Modalidade: {{ offerData.kind }}
              p.review-request-card__item Mensalidade: R${{ offerData.offeredPrice }} ({{ offerData.discountPercentage }}%)

          .restricted-offer__select-options
            label.restricted-offer__label Selecione a tratativa desejada
            Select.restricted-offer__select(
              placeholder="Selecione uma opção"
              :list="restrictedOfferOptions"
              v-model="formData.selectedOption"
              label-property="name"
            )

        div(v-if="isGenerateWithSpecificValue")
          .restricted-offer-specific-value
            label.restricted-offer-specific-value__label Insira o valor específico para a ordem
            input.restricted-offer-specific-value__input(
              type="text"
              name="specificOrderValue"
              v-model="formData.specificOrderValue"
              placeholder="Exemplo: 150.90"
              autocomplete="off"
              min="0"
              step="0.01"
            )

        .review-request__confirm-terms-checkbox
          Checkbox(
            v-model="formData.accepted"
            label="Confirmo que os dados estão corretos e estou ciente de que essa ação é irreversível."
          )

        Button.restricted-offer__submit-button(
          :disabled="isSubmitButtonDisabled"
          @click="submit"
          text="Vincular oferta ao aluno"
        )
</template>

<script>
import TabHeader from '@/components/common/TabHeader';
import Button from '@/components/common/Button';
import Card from '@/components/common/Card';
import Icon from '@/components/common/Icon';
import Select from '@/components/common/Select';
import Checkbox from '@/components/common/Checkbox';
import ProfileService from '@/assets/javascripts/services/ProfileService';
import OfferAPI from '@/api/offerAPI';

import { mapState, mapActions } from 'vuex';

export default {
  components: {
    TabHeader,
    Button,
    Card,
    Icon,
    Select,
    Checkbox,
  },
  data() {
    return {
      formData: {
        restrictedOfferId: null,
        selectedOption: null,
        specificOrderValue: null,
        accepted: false,
      },
      offerData: {
        courseName: null,
        kind: null,
        shift: null,
        university: null,
        offeredPrice: null,
        discountPercentage: null,
      },
      restrictedOfferOptions: [
        {
          name: 'Gerar ordem com PEF padrão',
          key: 'generate-default-order',
        },
        {
          name: 'Gerar ordem com valor específico',
          key: 'generate-with-specific-value',
        },
      ],
      offerDetails: false,
    };
  },
  computed: {
    ...mapState('Profile', ['profile', 'user']),
    isGenerateWithSpecificValue() {
      return (
        this.formData.selectedOption?.key === 'generate-with-specific-value'
      );
    },
    hasErrors() {
      const {
        restrictedOfferId,
        selectedOption,
        specificOrderValue,
      } = this.formData;
      if (this.isGenerateWithSpecificValue) {
        return !restrictedOfferId || !specificOrderValue;
      }
      return !restrictedOfferId || !selectedOption;
    },
    isSubmitButtonDisabled() {
      return this.hasErrors || !this.formData.accepted;
    },
  },
  methods: {
    ...mapActions('Profile', ['refreshProfile']),
    ...mapActions('Notifications', ['notifySuccess', 'notifyError']),

    async searchOffer() {
      try {
        const response = await OfferAPI.getOffer(
          this.formData.restrictedOfferId
        );
        const { course, offer } = response.data;

        this.offerDetails = true;

        this.offerData = {
          courseName: course.name,
          kind: course.kind,
          shift: course.shift,
          university: course.campus.university.name,
          offeredPrice: offer.offeredPrice,
          discountPercentage: offer.discountPercentage,
        };
      } catch (error) {
        const message =
          error?.response?.data?.message ||
          'Ocorreu um erro ao buscar a oferta.';
        this.notifyError(message);
      }
    },
    async submit() {
      try {
        const payload = {
          baseUserId: this.user.base_user,
          restrictedOfferId: this.formData.restrictedOfferId,
          selectedOption: this.formData.selectedOption.key,
        };

        if (this.isGenerateWithSpecificValue) {
          payload.specificOrderValue = this.formData.specificOrderValue;
        }

        await ProfileService.createOrderFromRestrictedOffer(payload);

        this.notifySuccess('Oferta vinculada com sucesso!');
        this.refreshProfile();
      } catch (error) {
        const message =
          error?.response?.data?.message ||
          'Ocorreu um erro ao vincular a oferta.';

        this.notifyError(message);
      }
    },
  },
};
</script>

<style lang="sass">
.restricted-offer
  background-color: $space-gray
  display: flex
  flex-direction: column
  height: 100vh
  line-height: normal

.restricted-offer__body
  overflow: auto
  padding: $space-st

.restricted-offer__title
  display: flex
  justify-content: space-between
  font-size: rem(18)
  font-weight: bold
  color: white
  padding-bottom: $space-m

.review-request-card__items-list
  margin-top: $space-m

.review-request-card__item
  margin-bottom: $space-m

.restricted-offer__search
  display: flex
  gap: $space-m
  justify-content: space-between
  align-items: center
  margin-bottom: 24px

.restricted-offer__label
  display: block
  margin-bottom: $space-m

.restricted-offer__group
  display: flex
  flex-direction: column

.restricted-offer__input
  @extend %opa-input
  width: 100%

.restricted-offer__input:disabled
  cursor: not-allowed
  opacity: 0.3

.restricted-offer__input::placeholder
  color: $chrome-gray

.restricted-offer__submit-button
  width: 100%
  margin-bottom: $space-s

.restricted-offer__select
  margin-bottom: $space-l

.restricted-offer-specific-value__label
  margin-bottom: $space-s

.restricted-offer-specific-value__input
  @extend %opa-input
  margin-top: $space-m
  margin-bottom: $space-l
  width: 100%

.review-request-card
  margin-bottom: $space-l

.review-request-card__header
  display: flex
  align-items: center
  gap: $space-s

.review-request-card__icon
  width: 24px
  height: 24px

.review-request-card__title
  font-size: rem(16)
  font-weight: bold
  margin: 0

.review-request-card__body
  margin-top: $space-m
  margin-bottom: $space-m

.review-request-card__checkbox
  margin-top: $space-l
  margin-bottom: $space-l
</style>
