export const actions = {
  async updatedInfo({ dispatch }, info) {
    await dispatch('Tickets/updatedInfo', info, { root: true });
  },
  showSearch({ dispatch }, params) {
    dispatch('OfferSearch/showSearchClicked', params, {
      root: true,
    });
  },
  transferClicked({ dispatch }, { customerId, tenantId }) {
    dispatch(
      'RightPanel/tabChange',
      {
        key: 'ticket-transfer',
        params: {
          customerId,
          tenantId,
        },
      },
      {
        root: true,
      }
    );
  },
  registerClicked({ dispatch }, { customerId, tenantId }) {
    dispatch(
      'RightPanel/tabChange',
      {
        key: 'register-contact',
        params: {
          customerId,
          tenantId,
        },
      },
      {
        root: true,
      }
    );
  },
  userCreated({ dispatch }, { baseUserId }) {
    dispatch('UserSearch/userCreated', { baseUserId }, { root: true });
  },
};
