import SocketService from '@/assets/javascripts/services/SocketService.js';
import HttpService from '@/assets/javascripts/services/HttpService.js';
import { convertToSnakeCase } from '@/assets/javascripts/modules/utils';
const { setListener, removeListener } = SocketService;

export default {
  getTickets(statusFilter, page) {
    const params = convertToSnakeCase({
      statusFilter: statusFilter,
      page: page,
    });

    return HttpService.get('api/tickets', params);
  },

  fetchTicket(params) {
    params = {
      ...params,
      version: process.env.OPA_CLIENT_VERSION,
    };

    return HttpService.get('api/tickets/fetch', params);
  },

  getContactReasons(tenantId) {
    const param = { tenantId };
    return HttpService.get('api/tickets/contact_reasons', param);
  },

  getDraftCustomerContactsAndChats(customerId) {
    return HttpService.get(
      `api/tickets/get_draft_customer_contacts_and_chats/${customerId}`
    );
  },

  registerContacts(
    customerContactIds,
    contactReasonId,
    observations,
    whatsappUsername,
    attachments
  ) {
    const params = convertToSnakeCase({
      customerContactIds,
      contactReasonId,
      observations,
      whatsappUsername,
      attachments,
    });

    return HttpService.post('api/tickets/register_contact', params);
  },

  changeBaseUser(customerId, newBaseUserId) {
    const params = {
      customerId: customerId,
      newBaseUserId: newBaseUserId,
    };

    return HttpService.post('api/tickets/change_base_user', params);
  },

  getTicketFor(customerId) {
    return HttpService.get(`api/tickets/customer/${customerId}`);
  },

  getActiveTicketForWhatsapp(whatsappUsername) {
    const param = { whatsappUsername: whatsappUsername };

    return HttpService.get('api/tickets/active_ticket_for', param);
  },

  startCustomerContact(customerId, contactType) {
    const params = {
      customer_id: customerId,
      contact_type: contactType,
    };

    return HttpService.post('api/tickets/start_contact', params);
  },

  transferTicket(
    customerContactId,
    targetType,
    targetSelected,
    whatsappChatId
  ) {
    const params = {
      customerContactId: customerContactId,
      targetType: targetType,
      targetSelected: targetSelected,
      whatsappChatId: whatsappChatId,
    };

    return HttpService.post('api/tickets/transfer', params);
  },

  forceTicketTransfer(customerId, type, currentAdminUserId, whatsappChatId) {
    const params = {
      customerId: customerId,
      type: type,
      currentAdminUserId: currentAdminUserId,
      whatsappChatId: whatsappChatId,
    };

    return HttpService.post('api/tickets/force_transfer', params);
  },

  listenForNewTickets(listener) {
    setListener('tickets:newTicket', listener);
  },

  listenForUpdateTicket(listener) {
    setListener('tickets:updateTicket', listener);
  },

  listenForCloseTicket(listener) {
    setListener('tickets:closeTicket', listener);
  },

  stopListening(type) {
    removeListener('tickets:closeTicket');
    removeListener(`tickets:${type}:newTicket`);
  },
};
